.MenuContainer {
    width: 100%;
    position: absolute;
   /* bottom: 12rem;*/
   top:1rem;
    left: 0;
  }
  
  .MenuWrapper {
    margin: auto;
    width: 100%;
    max-width: 1500px;
    margin: auto;
  }
  
  .MenuOptions h1 {
    text-align: right;
    color: #fff;
    font-size: 3em;
    font-family: sans-serif;
  }
  
  .MenuOptionsList {
    text-align: center;
    width: 100%;
    display: grid;
    list-style: none;
  }
  
  .MenuOptionsList li {

    padding: 0.5rem;
    color: #eee;
    font-family: sans-serif;
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    justify-self: right;
  }
  
  .MenuOptionsList select {
    width: 220px;
    margin-left: 0.5rem;
    padding: 0.5rem 1rem;
    outline: none;
    border-radius: 10px;
  
    font-size: 1.2em;
  }
  
  .VistaGeneral {
    width: 100%;
    display: flex;
    justify-content: right;
  }
  
  .VistaGeneral button {
    margin-left: 0.5rem;
    opacity: 1;
    margin-top: 0.5rem;
    width: 150px;
    padding: 0.5rem;
    outline: none;
    border-radius: 10px;
    background-color: #2fa1d6;
    color: #fff;
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: 200;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease;
  }
  
  .VistaGeneral button:hover {
    transition: 0.3s all ease;
    background-color: #2ff9d6;
  }